// ONLY INCLUDE MUST HAVE FILES FOR OUR APPS TO RUN
if (!window._babelPolyfill) {
    /* Set up some polyfills to protect against older browsers */
    require('@babel/polyfill')
}

const promiseFinally = require('promise.prototype.finally')
const BluebirdPromise = require('bluebird')

BluebirdPromise.config({ cancellation: true })

promiseFinally.shim()

require('@babel/runtime-corejs2/core-js/promise').default = Promise
require('nodent-runtime')

// This line in particular protects against Safari versions > 8
// where window.performance is not available.
// We specifically stub the now function b/c THREE.js depends on it
window.performance = window.performance || { now: Date.now }

// moment is an older style library and we make assumptions about
// timezones existing throughout the system
require('moment-timezone')
