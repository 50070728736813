import _ from 'lodash'

const PARSERS = {
    string: (val = '') => String(val || ''),
    boolean: (val = 'false') => String(val) === 'true',
    number: (val = null) => _.toFinite(val) || null,
    json: (val = '{}') => {
        var configs = JSON.stringify(val)

        return JSON.parse(configs) || {}
    },
}

const CONFIG_DEFINITION = {
    // Env variables
    GRAPHQL_API_REST_BASE: 'string',
    GTMID: 'string',
    SentryCurrentEnv: 'string',
    UseLogger: 'boolean',
    // Parameter store
    HeapAppId: 'string',
    MARKETO_APP_ID: 'string',
    ReleaseId: 'string',
    SentryDSN: 'string',
    RegionalConfigs: 'json',
    HotjarSiteConfig: 'string',
    PDFTRON_PDFNETJS_LIC: 'string',
}

const CONFIG = {}

let isStarted = false

export function setenv(input) {
    if (isStarted) {
        throw new Error('already called setenv')
    }

    Object.keys(CONFIG_DEFINITION).forEach(key => {
        const valueType = CONFIG_DEFINITION[key] || 'string'
        const parser = PARSERS[valueType]

        CONFIG[key] = parser(input[key])
    })

    isStarted = true
}

export function getenv(name) {
    if (!isStarted) {
        throw new Error('attempted to getenv before setenv was called')
    }

    if (Object.prototype.hasOwnProperty.call(CONFIG, name)) {
        return CONFIG[name]
    }
    throw new Error(`no config named '${name}'`)
}
